import { Translation } from './translations';

const NL: Translation = {
    blocks: {
        QuizBlock: {
            title: 'Wie heeft gelijk?',
            avatar_of: 'Profielfoto van {character}',
            character_is_right: '{character} heeft gelijk!',
            character_is_wrong:
                '{character} heeft het mis! Wie zou het dan goed hebben?',
            picked_answer_label: '(door jou gekozen)',
        },
    },
    components: {
        AccessibilityMenu: {
            preview_chat_message: 'Hoe lees jij het liefst? 😊',
            open_button: 'Opties en toegankelijkheid',
            section_general: 'Algemeen',
            option_dyslexia_font: 'Dyslexie-lettertype',
            option_increased_text_size: 'Berichten vergroten',
            option_static_chat_timeline: 'Chat uitvouwen',
            section_screenreaders: 'Schermlezers',
            option_screenreader_announcements: 'Chat live aankondigen',
            option_screenreader_announcements_info:
                '(pagina moet hiervoor misschien herladen worden)',
            option_restart: 'Opnieuw vanaf begin',
            option_restart_title:
                'Voortgang wissen en opnieuw beginnen met dit verhaal',
            option_restart_confirmation:
                'Alles wat je tot nu toe hebt gedaan bij "{subject}" wordt dan gewist.\n\nWeet je het zeker?',
        },
        AccessibleTimeline: {
            next_button: 'verder!',
            finished_button: 'klaar!',
        },
        CharacterNotification: {
            open_button: 'Open het bericht van {character}',
            avatar_alt: 'Profielfoto van {character}',
            close_button: 'Sluit het bericht van {character}',
            title: 'Chat',
        },
        PageNavigationButtons: {
            navigation_label: "Pagina's",
            back: 'Vorige pagina',
            forward: 'Volgende pagina',
        },
        StoryIntro: {
            intro_chat_message: 'Hoe lees jij het liefst? 😊',
            begin_button: 'beginnen!',
        },
        SubjectProgress: {
            meter_label: 'Voortgang van "{subject}"',
        },
    },
    doen: {
        FantasieMachine: {
            title: 'Fantasie AI',
            description: 'Maak grappige plaatjes met de Fantasie AI! 🤪',
            intro_chat_message: 'Wat voor plaatje wil je maken? 🤩',
            loading_chat_message:
                'De AI maakt nu een plaatje van {subject}, {action}, {location} {style}! 🤖 Hoe zou het eruit komen te zien? {emoji}',
            finished_chat_message: 'Enne... Wat vind je er van? 👀',
            error_missing_category: 'Vul eerst alle categorieën in.',
            error_chat_message:
                'De AI heeft het nu helaas te druk met fantaseren 😿 probeer je het over 1 minuut nog eens? 🪄',
            create_image: 'maken!',
            retry: 'nieuwe maken',
            retry_options: {
                question: 'Wat wil je maken?',
                image: 'plaatje',
                story: 'gedicht',
            },
            download: 'opslaan',
            create_story: 'verzin maar!',
            create_story_chat_message:
                'Ik zou kunnen proberen om een verhaaltje te verzinnen over {subject}, {action}, {location}! {emoji}',
            categories: {
                subject: 'wat',
                action: 'hoe',
                location: 'waar',
                style: 'stijl',
            },
            category_placeholders: {
                subject: 'wat wordt het?',
                action: 'hoe dan?',
                location: 'waar dan?',
                style: 'welke stijl?',
            },
            category_prefixes: {
                subject: 'een',
                action: '',
                location: 'op,in',
                style: 'als,in',
            },
            category_values: {
                hippo: 'een nijlpaard',
                strawberry: 'een aardbei',
                snowman: 'een sneeuwpop',
                teddy_bear: 'een knuffelbeer',
                donut: 'een donut',
                unicorn: 'een eenhoorn',
                spaceship: 'een raket',
                sunflower: 'een zonnebloem',
                tutu: 'in een tutu',
                bow_tie: 'met een strikje',
                upside_down: 'ondersteboven',
                dancing: 'dansend',
                reading: 'lezend',
                smiling: 'lachend',
                train_conductor: 'als machinist',
                magic_wand: 'met een toverstaf',
                wings: 'met vleugels',
                soccer: 'voetballend',
                on_vacation: 'op vakantie',
                superhero: 'als superheld',
                stilts: 'op stelten',
                office: 'op kantoor',
                island: 'op een eiland',
                moon: 'op de maan',
                mars: 'op mars',
                beach: 'op het strand',
                under_water: 'onder water',
                magical_forest: 'in een magisch bos',
                train: 'in een trein',
                trampoline: 'op een trampoline',
                castle: 'in een kasteel',
                painting: 'als schilderij',
                picasso: 'als Picasso',
                black_and_white: 'in zwart-wit',
                drawing: 'als tekening',
                three_dimensional: 'in 3D',
                cartoon: 'als cartoon',
                watercolor: 'als aquarel',
                pop_art: 'als pop-art',
                photo: 'als foto',
            },
            VerhalenVerzinner: {
                title: 'Poëzie AI',
                description: 'Test de Poëzie AI! 🤖',
                intro_chat_message: 'Wat zal ik voor je schrijven? 📖✏️',
                loading_chat_message:
                    'Hmmm, even denken hoor... {subject}, {action}, {location}! {emoji}',
                error_chat_message:
                    'De Poëzie AI heeft het nu helaas te druk met verzinnen 😿 probeer je het over 1 minuut nog eens? 🪄',
                create_story: 'verzinnen maar!',
                open_hint: 'Open het gedicht',
                share: 'delen',
                close: 'verder!',
            },
        },
        StijlSwap: {
            title: 'Stijl-Swap',
            description: 'Kies een stijl die bij jou past!',
            intro_chat_message: 'Maak een foto 📸 en kies een stijl! 🎨',
            loading: 'Laden',
            style: 'Stijl',
            styles: {
                udnie:
                    'Udnie. Een schilderij met veel kleurige vlakken. De kleuren zijn vooral grijs, blauw, groen en oranje.',
                de_schreeuw:
                    'De Schreeuw. Een schilderij met lange verfstroken in rood, blauw en beige. Iemand staat op een brug met handen op hun gezicht alsof er geschreeuwd wordt.',
                golf_van_kanagawa:
                    'Golf van Kanagawa. Een schilderij met een grote golf die over een boot heen slaat. De golf is blauw en wit, de lucht is oranje en er is een berg in de achtergrond.',
            },
            StijlSwapPhotoPicker: {
                user_picture_alt: 'Jouw foto',
                take_picture: 'foto maken',
                camera: 'Camera',
                camera_alt: 'Camerabeeld',
                camera_error:
                    'Er ging iets mis met het openen van de webcam, probeer het opnieuw of met een andere foto.',
                cancel: 'annuleren',
            },
            StijlSwapResult: {
                open: 'open',
                title: 'Resultaat',
                result_alt: 'Resultaat van de stijl-swap',
                save: 'opslaan',
                close: 'sluiten',
            },
        },
    },
};

export default NL;
