enum Colors {
    PRIMARY = 'primary-text-base-aa',
    ROSE = 'rose-text-base-aa',
    SECONDARY = 'secondary-text-base-aa',
    KEPPEL = 'keppel-text-base-aa',
    CINNAMON = 'cinnamon-text-base-aa',
    BLUMINE = 'blumine-text-lg-aa',
    SUNGLO = 'sunglo-text-base-aa',
}

export default Colors;

export const BackgroundColors = {
    default: 'bg-gradient-to-br from-secondary-950 to-secondary-900',
    greenish:
        'bg-gradient-to-b from-secondary-text-base-aaa to-keppel-text-lg-aaa',
    dark: 'bg-gradient-to-b from-rose-800 to-yellow-900',
};

// All color classes
{
    <>
        <noscript className="border-primary-text-base-aa bg-primary-text-base-aa text-primary-text-base-aa ring-primary-text-base-aa hover:bg-primary-text-base-aa hover:ring-primary-text-base-aa focus:ring-primary-text-base-aa outline-primary-text-base-aa hover:outline-primary-text-base-aa focus:outline-primary-text-base-aa" />
        <noscript className="border-rose-text-base-aa bg-rose-text-base-aa text-rose-text-base-aa ring-rose-text-base-aa hover:bg-rose-text-base-aa hover:ring-rose-text-base-aa focus:ring-rose-text-base-aa outline-rose-text-base-aa hover:outline-rose-text-base-aa focus:outline-rose-text-base-aa" />
        <noscript className="border-secondary-text-base-aa bg-secondary-text-base-aa text-secondary-text-base-aa ring-secondary-text-base-aa hover:bg-secondary-text-base-aa hover:ring-secondary-text-base-aa focus:ring-secondary-text-base-aa outline-secondary-text-base-aa hover:outline-secondary-text-base-aa focus:outline-secondary-text-base-aa" />
        <noscript className="border-keppel-text-base-aa bg-keppel-text-base-aa text-keppel-text-base-aa ring-keppel-text-base-aa hover:bg-keppel-text-base-aa hover:ring-keppel-text-base-aa focus:ring-keppel-text-base-aa outline-keppel-text-base-aa hover:outline-keppel-text-base-aa focus:outline-keppel-text-base-aa" />
        <noscript className="border-cinnamon-text-base-aa bg-cinnamon-text-base-aa text-cinnamon-text-base-aa ring-cinnamon-text-base-aa hover:bg-cinnamon-text-base-aa hover:ring-cinnamon-text-base-aa focus:ring-cinnamon-text-base-aa outline-cinnamon-text-base-aa hover:outline-cinnamon-text-base-aa focus:outline-cinnamon-text-base-aa" />
        <noscript className="border-blumine-text-lg-aa bg-blumine-text-lg-aa text-blumine-text-lg-aa ring-blumine-text-lg-aa hover:bg-blumine-text-lg-aa hover:ring-blumine-text-lg-aa focus:ring-blumine-text-lg-aa outline-blumine-text-lg-aa hover:outline-blumine-text-lg-aa focus:outline-blumine-text-lg-aa" />
        <noscript className="border-sunglo-text-base-aa bg-sunglo-text-base-aa text-sunglo-text-base-aa ring-sunglo-text-base-aa hover:bg-sunglo-text-base-aa hover:ring-sunglo-text-base-aa focus:ring-sunglo-text-base-aa outline-sunglo-text-base-aa hover:outline-sunglo-text-base-aa focus:outline-sunglo-text-base-aa" />
    </>;
}
