export default function imageLoader({
    src,
    width,
    quality,
}: {
    src: string;
    width: number | string;
    quality?: number | string;
}) {
    return `${src}?w=${width}&q=${quality || 85}&?fm=webp`;
}
