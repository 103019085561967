export const replaceLast = (text: string, search: string, replace: string) =>
    text.replace(new RegExp(search + '([^' + search + ']*)$'), replace + '$1');

export const slugify = (str: string) =>
    str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');

export const capitalizeFirstLetter = ([first = '', ...rest]: string) =>
    [first.toUpperCase(), ...rest].join('');
