import { AdditionalTranslation } from './translations';

const EN: AdditionalTranslation = {
    blocks: {
        QuizBlock: {
            title: 'Who is right?',
            avatar_of: 'Profile picture of {character}',
            character_is_right: '{character} is right!',
            character_is_wrong:
                '{character} is wrong! Who do you think is right?',
            picked_answer_label: '(picked by you)',
        },
    },
    components: {
        AccessibilityMenu: {
            preview_chat_message: 'How do you like to read? 😊',
            open_button: 'Options and accessibility',
            section_general: 'General',
            option_dyslexia_font: 'Dyslexia font',
            option_increased_text_size: 'Larger messages',
            option_static_chat_timeline: 'Expanded chat',
            section_screenreaders: 'Screenreaders',
            option_screenreader_announcements: 'Announce chat live',
            option_screenreader_announcements_info:
                '(you might need to reload the page)',
            option_restart: 'Restart from beginning',
            option_restart_title: 'Delete progress and restart this story',
            option_restart_confirmation:
                'Everything you have done so far in "{subject}" will be deleted.\n\nAre you sure?',
        },
        AccessibleTimeline: {
            next_button: 'continue!',
            finished_button: 'finish!',
        },
        CharacterNotification: {
            open_button: 'Open message by {character}',
            avatar_alt: 'Profile picture from {character}',
            close_button: 'Close message by {character}',
            title: 'Chat',
        },
        PageNavigationButtons: {
            navigation_label: 'Pages',
            back: 'Previous page',
            forward: 'Next page',
        },
        StoryIntro: {
            intro_chat_message: 'How do you like to read? 😊',
            begin_button: "let's go!",
        },
        SubjectProgress: {
            meter_label: 'Progress of "{subject}"',
        },
    },
    doen: {
        FantasieMachine: {
            title: 'Fantasy-Machine',
            description: 'Create funny pictures with the Fantasy-Machine! 🤪',
            intro_chat_message: 'What kind of picture do you want to make? 🤩',
            loading_chat_message:
                'The AI creating a picture of {subject}, {action}, {location} {style}! What will it look like? {emoji}',
            finished_chat_message: 'And... What do you think? 👀',
            error_missing_category: 'Please fill in all categories.',
            error_chat_message:
                'The AI is currently too busy fantasizing 😿 try again in 1 minute! 🪄',
            create_image: 'fantasize!',
            retry: 'create new',
            retry_options: {
                question: 'What would you like to create?',
                image: 'picture',
                story: 'poem',
            },
            download: 'save',
            create_story: 'make it up!',
            create_story_chat_message:
                'I could try to make up a poem about {subject}, {action}, {location}! {emoji}',
            categories: {
                subject: 'what',
                action: 'how',
                location: 'where',
                style: 'style',
            },
            category_placeholders: {
                subject: 'what will it be?',
                action: 'and how?',
                location: 'where?',
                style: 'which style?',
            },
            category_prefixes: {
                subject: 'a',
                action: '',
                location: 'on,in',
                style: 'as a,in',
            },
            category_values: {
                hippo: 'a hippo',
                strawberry: 'a strawberry',
                snowman: 'a snowman',
                teddy_bear: 'a teddy bear',
                donut: 'a donut',
                unicorn: 'a unicorn',
                spaceship: 'a spaceship',
                sunflower: 'a sunflower',
                tutu: 'in a tutu',
                bow_tie: 'with a bow tie',
                upside_down: 'upside down',
                dancing: 'dancing',
                reading: 'reading',
                smiling: 'smiling',
                train_conductor: 'as a train conductor',
                magic_wand: 'with a magic wand',
                wings: 'with wings',
                soccer: 'playing soccer',
                on_vacation: 'on a holiday',
                superhero: 'as a superhero',
                stilts: 'on stilts',
                office: 'in an office',
                island: 'on an island',
                moon: 'on the moon',
                mars: 'on mars',
                beach: 'on the beach',
                under_water: 'under water',
                magical_forest: 'in a magical forest',
                train: 'on a train',
                trampoline: 'on a trampoline',
                castle: 'in a castle',
                painting: 'as a painting',
                picasso: 'as Picasso',
                black_and_white: 'in black and white',
                drawing: 'as a drawing',
                three_dimensional: 'in 3D',
                cartoon: 'as a cartoon',
                watercolor: 'as a watercolor',
                pop_art: 'as pop-art',
                photo: 'in a photo',
            },
            VerhalenVerzinner: {
                title: 'Poem AI',
                description: 'Test the Poem AI! 🤖',
                intro_chat_message:
                    'What kind of Dutch poem do you want to hear? 📖',
                loading_chat_message:
                    'The Poem AI is currently making up a Dutch poem about {subject}, {action}, {location}! What will happen? {emoji}',
                create_story: 'make it up!',
                open_hint: 'Read the story',
                share: 'share',
                close: 'continue!',
            },
        },
        StijlSwap: {
            title: 'Style-Swap',
            description: 'Choose a style that suits you!',
            intro_chat_message: 'Take a picture 📸 and choose a style! 🎨',
            loading: 'Loading',
            style: 'Style',
            styles: {
                udnie:
                    'Udnie. A painting with many colorful surfaces. The colors are mainly gray, blue, green and orange.',
                de_schreeuw:
                    'The Scream. A painting with long strokes of paint in red, blue and beige. Someone is standing on a bridge with their hands on their face as if they are screaming.',
                golf_van_kanagawa:
                    'The Great Wave off Kanagawa. A painting with a big wave that is hitting a boat. The wave is blue and white, the sky is orange and there is a mountain in the background.',
            },
            StijlSwapPhotoPicker: {
                user_picture_alt: 'Your picture',
                take_picture: 'take picture',
                camera: 'Camera',
                camera_alt: 'Camera image',
                camera_error:
                    'Something went wrong when accessing the webcam, try again or try a different picture.',
                cancel: 'cancel',
            },
            StijlSwapResult: {
                open: 'open',
                title: 'Result',
                result_alt: 'Result of the style-swap',
                save: 'save',
                close: 'close',
            },
        },
    },
};

export default EN;
