import { useCallback } from 'react';
import { deleteAllUserSingleLargeValuesForSubject } from './useBlockLocalUserData';
import useLocalStorage from './useLocalStorage';
import { Subject } from './useSubject';
import { slugify } from '@/utils/strings';

export interface SubjectProgress {
    hasSeenIntro?: boolean;
    pages: Record<number, boolean>;
    blocks: Record<number, BlockProgress>;
    localUserSingleLargeValueIds: string[];
}

interface BlockProgress {
    value: object | undefined;
    updated_at: string;
}

export const defaultSubjectProgress: SubjectProgress = {
    hasSeenIntro: false,
    pages: {},
    blocks: {},
    localUserSingleLargeValueIds: [],
};

export function useSubjectProgress(
    subjectId: number,
): {
    subjectProgress: SubjectProgress;
    hasMadeProgress: boolean;
    setPageProgress: (pageId: number, finished: boolean) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setBlockProgress: (blockId: number, value: any) => void;
    setHasSeenIntro: (hasSeenIntro: boolean) => void;
    addLocalUserSingleLargeValueId: (id: string) => void;
    resetSubjectProgress: () => void;
} {
    const [
        subjectProgress,
        setSubjectProgress,
    ] = useLocalStorage<SubjectProgress>(
        `subjects[${subjectId}].progress`,
        defaultSubjectProgress,
    );

    // Add or remove pages from progress
    const setPageProgress = useCallback(
        (pageId: number, finished: boolean) => {
            setSubjectProgress((prev) => ({
                ...prev,
                pages: {
                    ...prev.pages,
                    [pageId]: finished,
                },
            }));
        },
        [subjectProgress, setSubjectProgress],
    );

    // Add or remove blocks from progress
    const setBlockProgress = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (blockId: number, value: any) => {
            setSubjectProgress((prev) => ({
                ...prev,
                blocks: {
                    ...prev.blocks,
                    [blockId]: {
                        value,
                        updated_at: new Date().toISOString(),
                    },
                },
            }));
        },
        [subjectProgress, setSubjectProgress],
    );

    // Save if the user has seen the intro, so we can skip it next time
    const setHasSeenIntro = useCallback(
        (hasSeenIntro: boolean) => {
            setSubjectProgress((prev) => ({
                ...prev,
                hasSeenIntro,
            }));
        },
        [subjectProgress, setSubjectProgress],
    );

    // Save the user's single large value ids for the subject, so we can delete them when the subject is deleted
    // They are stored in local db, logic is in useBlockLocalUserData.ts
    const addLocalUserSingleLargeValueId = useCallback(
        (id: string) => {
            const newIds = subjectProgress.localUserSingleLargeValueIds ?? [];
            if (newIds.includes(id)) {
                return;
            } else {
                newIds.push(id);
            }
            setSubjectProgress((prev) => ({
                ...prev,
                localUserSingleLargeValueIds: newIds,
            }));
        },
        [subjectProgress, setSubjectProgress],
    );

    const resetSubjectProgress = useCallback(() => {
        deleteAllUserSingleLargeValuesForSubject(
            subjectProgress.localUserSingleLargeValueIds ?? [],
        );
        setSubjectProgress(defaultSubjectProgress);
    }, [setSubjectProgress]);

    return {
        subjectProgress,
        hasMadeProgress:
            Object.values(subjectProgress.pages).some((finished) => finished) ||
            Object.values(subjectProgress.blocks).some(
                (blockProgress) => blockProgress.value,
            ),
        setPageProgress,
        setBlockProgress,
        setHasSeenIntro,
        addLocalUserSingleLargeValueId,
        resetSubjectProgress,
    };
}

export const calculateSubjectProgressPercentage = (
    subject: Subject,
    pageId?: number,
) => {
    const allPageIdsSorted = subject.chapters.reduce(
        (acc, chapter) => [...acc, ...chapter.page_ids],
        [] as number[],
    );

    let factor = 0;

    if (pageId) {
        // Calculate the factor base on the given pageId
        factor =
            (allPageIdsSorted.findIndex((p) => p === pageId) + 1) /
            allPageIdsSorted.length;
    } else {
        const progress = useSubjectProgress(subject.id);

        // Get the last page that was marked as finished
        const lastPageId = [...allPageIdsSorted]
            .sort((a, b) => b - a)
            .find((id) => progress.subjectProgress.pages[id]);

        if (lastPageId !== undefined) {
            factor =
                (allPageIdsSorted.findIndex((p) => p === lastPageId) + 1) /
                allPageIdsSorted.length;
        }
    }

    // Calculate the percentage
    return Math.round(factor * 100);
};

export const getLastFinishedPageUrl = (subject: Subject): string => {
    let url = `/story/${subject.id}/${slugify(subject.title)}`;

    const progress = useSubjectProgress(subject.id);

    const allPageIdsSorted = subject.chapters.reduce(
        (acc, chapter) => [...acc, ...chapter.page_ids],
        [] as number[],
    );

    // Get the last page that was marked as finished
    const lastPageId = [...allPageIdsSorted]
        .sort((a, b) => b - a)
        .find((id) => progress.subjectProgress.pages[id]);

    if (lastPageId !== undefined) {
        const chapter = subject.chapters.find((chapter) =>
            chapter.page_ids.includes(lastPageId),
        );

        if (chapter !== undefined) {
            url += `/${chapter.id}/${slugify(chapter.title)}/${lastPageId}`;
        }
    }

    return url;
};
