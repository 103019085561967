'use client';

import Twemoji from 'react-twemoji';

export const EmojiText = ({
    children,
}: {
    children: React.ReactNode | string;
}) => (
    <Twemoji
        options={{
            base: `${process.env.API_URL}/`,
            folder: 'emoji',
            size: 'svg',
            ext: '.svg',
        }}
        tag="span"
    >
        {children}
    </Twemoji>
);

export default EmojiText;
