'use client';

import { useEffect, useState } from 'react';
import { BlockProps } from '@/components/Block/Block';
import structuredClone from '@ungap/structured-clone';
import { set, get, del } from 'idb-keyval';
import { useSubjectProgress } from './useSubjectProgress';

export function useBlockLocalUserData<T>(
    subjectId: BlockProps['subjectId'],
    id: BlockProps['id'],
    blockUpdatedAt: BlockProps['updatedAt'],
    isEditorPreview: BlockProps['isEditorPreview'],
    defaultValue: T,
): { userDataLocal: T; setUserDataLocal: (value: T) => void } {
    const { subjectProgress, setBlockProgress } = useSubjectProgress(subjectId);

    const [editorLocalUserData, setEditorLocalUserData] = useState<T>(
        defaultValue,
    );

    // Only save temporary in memory if we are in editor preview
    if (subjectId === undefined || id === undefined || isEditorPreview) {
        return {
            userDataLocal: editorLocalUserData,
            setUserDataLocal: setEditorLocalUserData,
        };
    }

    // Check if the block is changed since the last time local data was saved
    let isBlockUpdatedSinceLastLocalUpdate = false;
    if (subjectProgress?.blocks?.[id]?.updated_at) {
        const lastLocalUpdate = new Date(
            subjectProgress?.blocks?.[id]?.updated_at,
        );
        const blockUpdatedAtDate = new Date(blockUpdatedAt);
        isBlockUpdatedSinceLastLocalUpdate =
            blockUpdatedAtDate > lastLocalUpdate;
    }

    // If the block is changed since the last time local data was saved, reset the local data to the default value
    let userDataLocal = (subjectProgress?.blocks?.[id]?.value ||
        defaultValue) as T;
    if (isBlockUpdatedSinceLastLocalUpdate) {
        userDataLocal = defaultValue;
    }

    return {
        userDataLocal,
        setUserDataLocal: (value: T) => {
            // Create a new variable, without reference to the previous one, to force a re-render
            setBlockProgress(id, structuredClone(value));
        },
    };
}

// Can be used to store a single large value, like an image, in IndexedDB
// They should not be stored in localStorage, as they are too large
export function useBlockLocalUserSingleLargeValue(
    subjectId: BlockProps['subjectId'],
    id: BlockProps['id'],
    isEditorPreview: BlockProps['isEditorPreview'],
    localUserSingleLargeValueId: string,
): {
    value: string | undefined;
    set: (value: string | undefined) => void;
} {
    const { addLocalUserSingleLargeValueId } = useSubjectProgress(subjectId);

    const [userSingleLargeValue, setUserSingleLargeValue] = useState<
        string | undefined
    >();

    const localValueId = `userSingleLargeValue-${subjectId ?? 'edit'}-${
        id ?? 'edit'
    }-${localUserSingleLargeValueId}`;

    // Only save temporary in memory if we are in editor preview
    if (subjectId === undefined || id === undefined || isEditorPreview) {
        return {
            value: userSingleLargeValue,
            set: setUserSingleLargeValue,
        };
    }

    useEffect(() => {
        (async () => {
            const value = await get<string>(localValueId);
            if (value) addLocalUserSingleLargeValueId(localValueId);
            setUserSingleLargeValue(value);
        })();
    }, []);

    return {
        value: userSingleLargeValue,
        set: async (value: string | undefined) => {
            addLocalUserSingleLargeValueId(localValueId);
            await set(localValueId, value);
            setUserSingleLargeValue(value);
        },
    };
}

export function deleteAllUserSingleLargeValuesForSubject(
    localUserSingleLargeValueIds: string[],
): void {
    localUserSingleLargeValueIds.forEach(async (localValueId) => {
        await del(localValueId);
    });
}
